import {
  ref, watch, computed,
} from '@vue/composition-api'
import store from '@/store'
import { title, dateLatin } from '@/@core/utils/filter'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import moment from 'moment'

export default function useUsersView() {
  // Use toast
  const toast = useToast()
  const { t } = useI18nUtils()

  const refMembershipListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'client', label: t('Clients.clientList.columnName'), sortable: true },
    {
      key: 'plan',
      label: t('Memberships.membershipList.planName'),
      formatter: title,
      sortable: false,
    },
    {
      key: 'cost',
      label: t('Memberships.membershipList.cost'),
      // formatter: title,
      sortable: false,
    },
    {
      key: 'totalPayment',
      label: t('Memberships.membershipList.totalPayment'),
      // formatter: title,
      sortable: false,
    },
    {
      key: 'debt',
      label: t('Memberships.membershipList.debt'),
      sortable: false,
    },
    {
      key: 'paymentMethod',
      label: t('Planes.planList.paymentMethod'),
      sortable: false,
    },
    {
      key: 'credits',
      label: t('Memberships.data.creditsShort'),
      formatter: title,
      sortable: false,
    },
    {
      key: 'creditsAvailable',
      label: t('Memberships.data.creditsAvailableShort'),
      formatter: title,
      sortable: false,
    },
    {
      key: 'attendance',
      label: t('Planes.planList.attendanceShort'),
      formatter: title,
      sortable: false,
    },
    {
      key: 'created',
      label: t('Memberships.data.created'),
      // formatter: dateLatin,
      sortable: false,
    },
    {
      key: 'start',
      label: t('Planes.planList.startDate'),
      // formatter: dateLatin,
      sortable: false,
    },
    {
      key: 'end',
      label: t('Planes.planList.endDate'),
      // formatter: dateLatin,
      sortable: true,
    },
    {
      key: 'actions',
      label: t('Planes.planList.actions'),
      sortable: false,
    },
  ]

  const userData = ref(null)
  const membershipPrepaid = ref(null)
  const listMembershipsAssosied = ref(null)
  const totalRootClient = ref(0)
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refMembershipListTable.value ? refMembershipListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchListMembership = () => {
    refMembershipListTable.value.refresh()
  }
  const fetchUser = () => {
    store.dispatch('app-user/fetchUser', {
      id: router.currentRoute.params.id,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
    })
      .then(response => {
        const {
          client, clientMembershipPrepaid, membershipsRootClient, total,
        } = response.data
        userData.value = client
        membershipPrepaid.value = clientMembershipPrepaid
        listMembershipsAssosied.value = membershipsRootClient
        totalRootClient.value = total.toString()
      })
      .catch(error => {
        console.log(error)
        if (error.response.status === 404) {
          userData.value = undefined
        }
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error al buscar los datos del cliente',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  watch([currentPage, perPage, sortBy, isSortDirDesc], () => {
    fetchUser()
    refetchListMembership()
  })

  const deleteUser = (selectedUser, emit) => {
    store.dispatch('app-user/deleteUser', selectedUser.id)
      .then(async () => {
        emit('handle-update-status-client')
        emit('update:is-delete-user-sidebar-active', false)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Aviso',
            text: 'Cliente desactivado',
            icon: 'InfoIcon',
            variant: 'success',
          },
        })
      }).catch(error => {
        console.log(error)
        if (error.response.data) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: error.response.data.error,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }
  const addAttendanceUser = (selectedUser, emit) => {
    store.dispatch('app-user-attendance/addAttendanceUser', selectedUser.id)
      .then(async () => {
        emit('handle-update-button-attendance')
        emit('update:is-user-attendance-sidebar-active', false)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Aviso',
            text: 'Asistencia registrada',
            icon: 'InfoIcon',
            variant: 'success',
          },
        })
      }).catch(error => {
        console.log(error)
        if (error.response.data) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: error.response.data.error,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status) return 'success'
    if (!status) return 'danger'
    return 'warning'
  }

  const resolveAttendanceButtonVariant = status => {
    if (status) return 'primary'
    return 'secondary'
  }

  const resolvePlanStatusVariant = status => {
    if (status === 0) return 'danger'
    if (status === 1) return 'info'
    if (status === 2) return 'danger'
    return 'warning'
  }
  const resolvePlanStatus = status => {
    if (status === 1) return t('Planes.status.active')
    if (status === 2) return t('Planes.status.Suspended')
    if (status === 3) return t('Planes.status.delete')
    return t('Planes.status.pending')
  }

  const resolveUserStatus = status => {
    if (status === false) return t('Clients.status.inactive')
    if (status === true) return t('Clients.status.active')
    return t('Clients.status.pending')
  }
  const resolveDateStatus = (status, expireToDay) => {
    if (status && !expireToDay) return t('statusMemberships.inactive')
    if (!status && expireToDay) return t('statusMemberships.expireToDay')
    if (status === false) return t('statusMemberships.activeDate')
    return t('statusMemberships.noMembership')
  }

  const resolveDateStatusVariant = status => {
    if (status) return 'warning'
    if (!status) return 'success'
    return 'warning'
  }
  const getDate = () => moment().startOf('day')
  const formatDate = date => {
    const myMomentObject = moment(date)
    return myMomentObject.format('DD-MM-YYYY HH:mm')
  }
  return {
    fetchUser,
    formatDate,
    deleteUser,
    getDate,
    resolvePlanStatus,
    addAttendanceUser,
    resolveAttendanceButtonVariant,
    membershipPrepaid,
    listMembershipsAssosied,
    userData,
    totalRootClient,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refMembershipListTable,
    resolvePlanStatusVariant,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatus,
    resolveUserStatusVariant,
    refetchListMembership,
    resolveDateStatus,
    resolveDateStatusVariant,
    toast,
    // Extra Filters
    t,
  }
}
