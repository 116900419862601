<template>
  <div class="v-select vs--single vs--searchable">
    <b-form-input
        required
      v-model="searchTerm"
      :value="selectedValue"
      :placeholder="$t('searchText')+'...'"
      @input="handleInput"
    />
    <ul
      v-if="showResults"
      class="vs__dropdown-menu"
      tabindex="-1"
    >
      <li
        v-for="(data) in searchResults"
        :key="data.value"
        class="vs__dropdown-option"
        @click="selectOption(data)"
      >
        {{ data.label }}
      </li>
    </ul>
  </div>

</template>

<script>
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import toast from 'bootstrap/js/src/toast'
import { BFormInput } from 'bootstrap-vue'

export default {
  components: { BFormInput },
  props: {
    value: {
      type: Object,
      required: true,
    },
    selectedClientId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      searchTerm: '',
      searchResults: [],
      showResults: false,
      selectedValue: '',
    }
  },
  methods: {
    handleInput() {
      if (this.searchTerm.length > 3 && this.searchTerm.length <= 8) {
        store
          .dispatch('app-user/fetchUsers', {
            query: this.searchTerm,
            perPage: 10,
            page: 1,
            sortBy: '',
            sortDesc: '',
            planId: '',
            clientStatus: true,
          })
          .then(response => {
            const { clients } = response.data

            const op = []
            clients.map(client => {
              if (this.selectedClientId !== client.id) {
                op.push({
                  label: `${client.last_name},  ${client.first_name} - ${client.dni}`,
                  value: client.id.toString(),
                })
              }
            })
            this.searchResults = op
            this.showResults = true
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error al listar los clientes',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      } else {
        this.showResults = false
      }
    },
    selectOption(option) {
      this.value.clientId = option.value
      this.selectedValue = option.value
      this.searchTerm = option.label
      this.showResults = false
    },
  },
}
</script>
