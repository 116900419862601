import useJwt from '../../auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchMemberships(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        useJwt.axiosIns
          .post('/membership/search', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMembership(ctx, { id }) {
      return new Promise((resolve, reject) => {
        useJwt.axiosIns
          .get(`/membership/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addMembership(ctx, membershipData) {
      return new Promise((resolve, reject) => {
        useJwt.axiosIns
          .post('/membership', membershipData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editMembership(ctx, membershipData) {
      return new Promise((resolve, reject) => {
        useJwt.axiosIns
          .patch(`/membership/${membershipData.id}`, membershipData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    upgradeMembership(ctx, membershipData) {
      return new Promise((resolve, reject) => {
        useJwt.axiosIns
          .patch(`/membership/upgrade/${membershipData.id}`, membershipData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteMembership(ctx, membershipData) {
      return new Promise((resolve, reject) => {
        useJwt.axiosIns
          .delete(`/membership/${membershipData.id}`, membershipData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
